//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
import { SET_BC } from '~/store/bc/types.bc'

export default {
  components: {
    Breadcrumbs,
    Gallery: () => import('~/components/UI/Gallery/Gallery.vue'),
    ProductNavigation: () =>
      import('~/components/pages/Product/components/ProductNavigation.vue'),
    ProductDescriptions: () =>
      import('~/components/pages/Product/ProductDescriptions.vue'),
    ProductsSlider: () =>
      import('~/components/modules/Sliders/ProductsSlider.vue'),
    ReviewsModule: () =>
      import('~/components/modules/Reviews/ReviewsModule.vue'),
  },
  data() {
    return {
      product: {},
      similar: [],
      navigation: [],
      itemInfo: {},
    }
  },
  fetch() {
    return this.fetchProduct()
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  methods: {
    fetchProduct() {
      const slug = this.$route.params.id
      return this.$request.$get(`/product/${slug}`).then(({ data }) => {
        const { similar, blocks, breadcrumbs, name, files } = data
        this.itemInfo = {
          item_name: name,
          file_preview_url: files[0].url,
          slug: data.slug,
        }
        this.similar = similar.map((s, i) => {
          const variationsKeys = Object.keys(s.variations).filter(
            (key) => key !== 'primary',
          )
          const variations =
            s.variations[
              s.variations.primary ? s.variations.primary : variationsKeys[0]
            ]
          return {
            ...s,
            id: i,
            variations:
              variations && variations[0]
                ? variations.map((variation) => ({
                  ...variation,
                  file: variation.files[0]?.url || '',
                }))
                : [],
          }
        })
        this.$store.commit(`bc/${SET_BC}`, [
          { name: breadcrumbs[0].name, slug: `/product/catalog/${breadcrumbs[0].slug}` },
          { name: data.name, slug: data.slug },
        ])
        this.product = {
          ...data,
          files: data.files.map((file) => file.url),
          variations: data.variations,
        }
        const blocksNav = blocks.map((block) => {
          const to = block.name.split(' ')[0].toLowerCase()
          return {
            name: block.name,
            to,
          }
        })
        this.navigation = [
          ...blocksNav,
          ...[
            {
              name: this.$t('specification'),
              to: 'specification',
            },
            {
              name: `${this.$t('reviews')}(${this.product.reviews_count})`,
              to: 'reviews',
            },
          ],
        ]
        if (!this.settings.reviews) {
          this.navigation = this.navigation.filter(item => item.to !== 'reviews')
        }
      })
    },
    handleNavScroll(i) {
      const element = document.getElementById(`section-${i}`)
      const top = element.offsetTop - 150
      window.scrollTo({
        behavior: 'smooth',
        top,
      })
    },
  },
}
